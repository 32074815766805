<template>
  <div id="design-size">
    <h1 class="size__title">製衣碼數估算</h1>
    <div class="size__title-divider" />

    <div class="size__container">
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/women_s blouse.png')" alt="">
        <p class="size__item-name">女性襯衫</p>
        <p class="size__item-size">2 - 3 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/t shiry.png')" alt="">
        <p class="size__item-name">T恤</p>
        <p class="size__item-size">1.5 - 2 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/dress.png')" alt="">
        <p class="size__item-name">洋裝</p>
        <p class="size__item-size">2 - 6 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/skirt.png')" alt="">
        <p class="size__item-name">裙子</p>
        <p class="size__item-size">1.5 - 3 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/pants.png')" alt="">
        <p class="size__item-name">長褲</p>
        <p class="size__item-size">2 - 4 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/short.png')" alt="">
        <p class="size__item-name">短褲</p>
        <p class="size__item-size">1 - 2.5 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/men_s shirt.png')" alt="">
        <p class="size__item-name">男性襯衫</p>
        <p class="size__item-size">2 - 3 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/maxi skirt.png')" alt="">
        <p class="size__item-name">長裙</p>
        <p class="size__item-size">2 - 5 碼</p>
      </div>
      <div class="size__item">
        <img class="size__item-img" :src="require('@/assets/designs/type/coat.png')" alt="">
        <p class="size__item-name">大衣</p>
        <p class="size__item-size">5 - 7 碼</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="stylus">
#design-size
  padding 50px 0

.size__title
  font-size $font-size-title
  color #4A9A8A
  padding 10px 0

.size__title-divider
  height 5px
  background #957200
  border-radius 4px
  width 100%

.size__container
  flex(flex-start, flex-start)
  flex-wrap wrap;
  margin 0 -2% 0 0

.size__item
  flex(center, center, column)
  padding 35px 70px
  background #D1E7E0
  width 31.333%
  margin 2% 2% 0 0

.size__item-img
  width 100px
  height 100px

.size__item-name
  color #957200
  font-size $font-size-lg3x
  font-weight 700
  margin-top 15px

.size__item-size
  color black
  font-size $font-size-lg2x
  font-weight 600
  margin-top 10px
</style>
